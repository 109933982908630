<template>
  <section class="properties" style="width: 100%">
    <router-view />
  </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Projects",
});
</script>
